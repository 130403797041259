<template>
  <div id="main" ref="main" style="width:100vw;height:100vh" />
</template>
<script>
import * as echarts from 'echarts/core'
import '../../../utils/world'

import {
  CanvasRenderer
} from 'echarts/renderers'
import {
  Lines3DChart
} from 'echarts-gl/charts'
import {
  Geo3DComponent
} from 'echarts-gl/components'

echarts.use(
  [Geo3DComponent, Lines3DChart, CanvasRenderer]
)

export default {
  data() {
    return {
      a: 1
    }
  },

  mounted() {
    this.update()
  },
  methods: {
    getAirportCoord(idx, data) {
      return [data.points[idx][0], data.points[idx][1]]
    },
    update() {
      const chartDom = this.$refs['main']
      const myChart = echarts.init(chartDom)
      this.$api.GetInquiryFlow().then(result => {
        const data = result.data
        const points = data.points.map((item) => {
          return {
            coord: [item[0], item[1]]
          }
        })
        const routesGroupByAirline = {}
        data.routes.forEach(function(route) {
          const airline = data.groups[route[0]]
          const airlineName = airline[0]

          if (!routesGroupByAirline[airlineName]) {
            routesGroupByAirline[airlineName] = []
          }
          routesGroupByAirline[airlineName].push(route)
        })

        const pointsData = []
        data.routes.forEach((airline) => {
          pointsData.push(this.getAirportCoord(airline[1], data))
          pointsData.push(this.getAirportCoord(airline[2], data))
        })

        const series = data.groups.map((airline) => {
          const airlineName = airline[0]
          const trailColor = airline[1]
          const period = airline[2]
          const routes = routesGroupByAirline[airlineName]
          if (!routes) {
            return null
          }

          return {
            type: 'lines3D',
            coordinateSystem: 'geo3D',
            name: airlineName,
            effect: {
              show: true, // 是否显示尾迹特效
              trailWidth: 2, // 尾迹的宽度
              trailLength: 0.15, // 尾迹的长度，范围从 0 到 1，为线条长度的百分比
              period: period, // 尾迹特效的周期，值越小速度越快
              spotIntensity: 2, // 亮点强度，值越大越亮
              trailOpacity: 1,
              trailColor: trailColor // 线条颜色
            },
            lineStyle: {
              width: 1,
              color: 'rgb(50, 50, 150)', // 轨迹颜色
              opacity: 0 // 轨迹透明度
            },
            blendMode: 'lighter',

            data: routes.map(function(item) {
              return [points[item[1]].coord, points[item[2]].coord]
            })
          }
        }).filter(function(series) {
          return !!series
        })
        myChart.setOption({
          legend: {
            selectedMode: 'multiple',
            left: 'left',
            data: Object.keys(routesGroupByAirline),
            orient: 'vertical',
            textStyle: {
              color: '#fff'
            }
          },
          geo3D: {
            map: 'world', // 引入的地图 world是世界地图,china则中国地图
            shading: 'color',
            environment: require('../../../static/img/dataView/view_bg.jpg'), // 背景图片
            silent: true, // 静默模式
            groundPlane: {
              show: false // 不显示地面图
            },
            viewControl: {
              distance: 50 // 高度80
            },
            show: false // 不显示地图
          },
          series: series
        })
      })
      setTimeout(() => {
        this.update()
      }, 30000)
    }
  }
}
</script>

